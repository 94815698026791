import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const TechnicalFeatures = ({ config }) => {
	const { imageSrc, headingCopy, bullets } = config;

	return (
		<div className="flex flex-col items-center gap-8 md:flex-row max-w-7xl m-auto py-8 px-4">
			<div className="w-full md:w-1/2">
				<Typography
					component="h2"
					variant="subhead-lg"
					className="mb-12 font-bold text-2xl md:text-3xl">
					{headingCopy}
				</Typography>

				<div className="flex flex-col gap-8">
					{bullets.map((bullet, index) => (
						<div key={index} className="flex items-start gap-4">
							{bullet.icon && (
								<div className="h-10 w-10 flex-shrink-0">
									<Image src={bullet.icon} alt={bullet.iconAlt || ''} width={62} height={62} />
								</div>
							)}
							<div>
								<Typography
									component="h3"
									variant="subtitle-lg"
									className="mb-1 font-semibold text-lg">
									{bullet.heading}
								</Typography>
								<Typography component="p" variant="body" className="text-base">
									{bullet.body}
								</Typography>
							</div>
						</div>
					))}
				</div>
			</div>

			<Image
				src={imageSrc}
				alt="Technical Features"
				width={600}
				objectFit="contain"
				height={600}
				className="h-auto w-full"
			/>
		</div>
	);
};

TechnicalFeatures.propTypes = {
	config: PropTypes.shape({
		imageSrc: PropTypes.string.isRequired,
		headingCopy: PropTypes.string.isRequired,
		bullets: PropTypes.arrayOf(
			PropTypes.shape({
				icon: PropTypes.string.isRequired,
				iconAlt: PropTypes.string,
				heading: PropTypes.string.isRequired,
				body: PropTypes.string.isRequired,
			}),
		).isRequired,
		customClass: PropTypes.string,
		customFlexClass: PropTypes.string,
	}).isRequired,
};

export default TechnicalFeatures;
