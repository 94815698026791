import Clickable from '/components/Clickable';
import Markdown from '/components/Markdown';
import PropTypes from 'prop-types';
import Stars from '/components/Stars';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const HeroText = ({ config }) => {
	const alignments = {
		center: 'flex flex-col items-center justify-center mt-4 md:mx-4 text-center h-full',
		left: 'justify-start xl:justify-start xl:text-left text-left',
		right: 'xl:justify-end xl:text-left',
	};
	const buttonAlignments = {
		center: '',
		left: 'justify-start xl:justify-start',
		right: 'justify-center xl:justify-start',
	};

	return (
		<div
			className={classNames(
				config.heroVersion === 'v2'
					? alignments[config.alignment]
						? alignments[config.alignment]
						: alignments.left
					: 'flex flex-col items-left justify-start md:ml-10 md:mt-0 md:text-left mt-4 mx-4 text-left',
				!config.buttons?.length && 'h-full flex items-center md:pt-40 justify-center max-w-xl',
			)}>
			<Markdown content={config.content} />

			{config.buttons && config.buttons.length > 0 && (
				<div
					className={classNames(
						'flex gap-4 md:mt-4 mt-2',
						config.heroVersion === 'v2'
							? buttonAlignments[config.alignment]
								? buttonAlignments[config.alignment]
								: buttonAlignments.left
							: 'justify-start',
					)}>
					{config.buttons.map((button, i) => {
						return (
							<Clickable
								className="bg-v2-off-white font-bold px-4 py-3 rounded-md text-base text-primary tracking-widest uppercase"
								heapEventData={{
									'click-anchor-href': button.anchorHref,
									'click-text': button.text,
								}}
								heapEventName="hero-button-click"
								key={i}
								linkToInternal={button.anchorHref}>
								<Typography variant="body-heavy">{button.text}</Typography>
							</Clickable>
						);
					})}
				</div>
			)}
			{config.starText && (
				<div className="mt-4 flex items-center gap-2">
					<Stars rating={4.9} totalStars={5} />
					<Typography variant="subhead-sm">{config.starText}</Typography>
				</div>
			)}
		</div>
	);
};

HeroText.propTypes = {
	config: PropTypes.object,
};

export default HeroText;
